export const environment = {
  production: true,
  agGridLicence:
    // eslint-disable-next-line max-len
    'Using_this_AG_Grid_Enterprise_key_( AG-044955 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Morningstar Indexes Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( indexengine )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( indexengine )_need_to_be_licensed___( indexengine )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 9 September 2024 )____[v2]_MTcyNTgzNjQwMDAwMA==12907399617f5aac7226548495a56248',
  apiUrl: 'https://api.indexengine.mif0286.eas.morningstar.com/v1',
  eventSurveillanceUrl: 'https://mist.morningstar.com/event-surveillance/ui/?#/tracked-event/',
  streamOptions: {
    url: 'https://api.indexengine.mif0286.eas.morningstar.com/',
    options: {
      path: '/v1/stream',
      reconnectionDelay: 10000,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: Infinity,
      timeout: 60000,
    },
  },
  auth: {
    domain: 'f19kl.eu.auth0.com',
    clientID: 'Z3gTDRWyu8hqaYUeSLRVJJhiDnqDtPCh',
    loginRedirect: '/login',
    options: {
      autoclose: false,
      language: 'en',
      container: 'loginContainer',
      auth: {
        audience: 'moorgate-service',
        responseType: 'token id_token',
        redirectUrl: `${window.location.origin}/login`,
      },
      theme: {
        primaryColor: '#0077cf',
        foregroundColor: '#ffffff',
        logo: 'assets/logo.svg',
      },
      languageDictionary: {
        title: '',
      },
    },
  },
  updateInterval: 1,
};
